import React from "react";
import styled from "styled-components";

const HeaderWrapper = styled.div`
  margin: 64px 0 64px 0;
  text-align: center;
  @media (max-width: 640px) {
    padding: 0 16px;
  }
`;

const H1 = styled.h1`
  font-size: 40px;
  font-family: "Merriweather", serif;
  font-weight: 700;
  @media (max-width: 640px) {
    font-size: 32px;
  }
`;

const Year = styled.p`
  font-size: 14px;
  margin-top: 8px;
  font-weight: 500;
  color: #757575;
`;

const ProjectHeader = props => (
  <HeaderWrapper>
    <H1>{props.title}</H1>
    <Year>{props.year}</Year>
  </HeaderWrapper>
);

export default ProjectHeader;
