import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { motion } from "framer-motion";

import LockIcon from "../../Assets/lock.svg";

const PasswordWrapper = styled.div`
  padding: 32px;
  padding-top: 10vh;
  text-align: center;
`;

const H2 = styled(motion.h2)`
  font-size: 32px;
  font-weight: 500;
  margin-top: 24px;
`;

const StyledForm = styled(motion.form)`
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
  margin-top: 32px;
`;

const StyledInput = styled.input`
  border: none;
  outline: none;
  display: block;
  width: 100%;
  padding: 24px;
  font-size: 20px;
  border-radius: 8px;
  background: #ededed;
  margin-bottom: 48px;
`;

const SubmitButton = styled.button`
  outline: none;
  border: none;
  width: 100%;
  padding: 24px;
  background-color: #0035a7;
  color: white;
  font-size: 18px;
  font-weight: 500;
  border-radius: 8px;
  cursor: pointer;
  transition: background 200ms ease-out;

  :hover {
    background: #00277a;
  }
`;

const ErrorWrapper = styled(motion.p)`
  color: red;
  margin-top: 16px;
`;

class Password extends React.Component {
  constructor() {
    super();
    this.state = {
      userPass: "",
      passwordError: false,
      incorrectTimes: 0
    };
  }

  handleChange = e => {
    if (this.state.passwordError) {
      this.setState({ userPass: e.target.value, passwordError: false });
    } else {
      this.setState({ userPass: e.target.value });
    }
  };

  checkPassword = e => {
    e.preventDefault();
    // yea def don't hire me as a security engineer hahahaha shit
    if (this.state.userPass === "bagel") {
      this.props.handleCheck();
    } else {
      this.setState({
        passwordError: true,
        incorrectTimes: this.state.incorrectTimes + 1
      });
    }
  };

  getErrorMessage = () => {
    if (this.state.incorrectTimes < 2) {
      return "mmmm incorrect, try again";
    } else if (this.state.incorrectTimes < 3) {
      return "nope...hey did I even give you the password?";
    } else {
      return "ok I'm calling security";
    }
  };

  render() {
    return (
      <PasswordWrapper>
        <motion.img
          src={LockIcon}
          transition={{ duration: 0.5, delay: 0.8 }}
          initial={{ opacity: 0, y: 0 }}
          animate={{ opacity: 1, y: 0 }}
        />
        <H2
          transition={{ duration: 0.3, delay: 0.1 }}
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
        >
          What's the magic word?
        </H2>
        <StyledForm
          transition={{ duration: 0.3, delay: 0.5 }}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
        >
          <StyledInput
            type="password"
            value={this.state.userPass}
            onChange={this.handleChange}
            placeholder="password"
            autoFocus
          />

          {this.state.userPass === "bagel" ? (
            <SubmitButton onClick={this.checkPassword}>
              Open sesame ;)
            </SubmitButton>
          ) : (
            <SubmitButton onClick={this.checkPassword}>
              Open sesame
            </SubmitButton>
          )}
          {this.state.passwordError && (
            <ErrorWrapper
              transition={{ duration: 0.15, delay: 0 }}
              initial={{ opacity: 0, y: 15 }}
              animate={{ opacity: 1, y: 0 }}
            >
              {this.getErrorMessage()}
            </ErrorWrapper>
          )}
        </StyledForm>
      </PasswordWrapper>
    );
  }
}

Password.propTypes = {
  handleCheck: PropTypes.func.isRequired
};

export default Password;
