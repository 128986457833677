import React from "react";
import styled from "styled-components";

import ProjectHeader from "../UI/ProjectHeader";
import ImageWrapper from "../UI/ImageWrapper";
import Nav from "../UI/Nav";

import Sheets from "../../Assets/Hackathon/maps-sheets.png";
import Proto from "../../Assets/Hackathon/maps-proto.mov";
import Figma from "../../Assets/Hackathon/maps-figma.png";
import Demo from "../../Assets/Hackathon/maps-demo.mov";

const ProjectWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 120px;
  padding-top: 48px;
  @media (max-width: 640px) {
    padding: 0 24px;
  }
`;

const Paragraph = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
  font-size: 18px;
  line-height: 1.7;
`;

const ResultsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 16px;
  max-width: 640px;
  text-align: left;
`;

const Results = styled.h3`
  font-size: 20px;
  font-weight: 500px;
`;

const Hackathon = () => (
  <div>
    <Nav />

    <ProjectWrapper>
      <ProjectHeader title={"Blend Maps"} year={"2019"} />

      <Paragraph>
        For the 2019 hackathon, I paired with two engineers to create Blend
        Maps: an interactive employee map.
      </Paragraph>
      <Paragraph>
        The problem we were solving is one that every startup encounters at a
        certain size: the office seating chart (in our case, maintained using
        Google Sheets) doesn't scale indefinitely with company growth.
      </Paragraph>
      <Paragraph>
        For one, finding people becomes increasingly challenging as the company
        grows. Our seating chart had expanded to multiple tabs, and Google
        Sheets doesn't default to searching across tabs, so you'd potentially
        have to perform an advanced search or search on each tab. And of course,
        it wasn't pretty to look at.
      </Paragraph>
      <Paragraph>
        To make the UX even worse, few people have the seating chart link handy.
        That is, finding someone is usually a two step process: 1) ask around to
        get the seating chart link, then 2) find the person in the seating
        chart. And there's sometimes a third step, which is looking up the
        person on slack to check their picture so you know "what" to look for
        when you embark on your quest to find them.
      </Paragraph>
      <Paragraph>
        There are other problems as well, e.g. maintenance—adding new employees
        and re-arranging "old" becomes exponentially more tedious and
        error-prone as more people join—but we needed to keep a narrow scope
        cause hackathon.
      </Paragraph>
      <ImageWrapper
        path={Sheets}
        video={false}
        caption={
          "Using Google Sheets as our seating chart wasn't scaling well with company growth."
        }
      />
      <Paragraph>
        I was in charge of the front-end, which needed to have 1) a way to
        search employees, 2) an interactive map that highlights the searched
        employee's desk, and 3) time-permitting, additional meta-data about that
        employee (e.g. profile picture).
      </Paragraph>
      <Paragraph>
        I wasn't worried about the searching of employees, as I had some prior
        experience building autocomplete inputs (often building on top of
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://github.com/downshift-js/downshift"
            target="_blank"
            rel="noopener noreferrer"
          >
            Downshift
          </a>
        </span>
        ). But I wasn't immediately sure how to tackle the map highlighting. So
        my first order of business was messing around in code to come up with a
        feasible approach.
      </Paragraph>
      <Paragraph>
        I went down a few paths that were dead ends, but ultimately landed on
        using SVGs to represent each interactive element on the map.
        Essentially, we could assign each employee an ID, then (manually) tag
        that employee's desk with that ID in the SVG map, such that selecting an
        employee then loops through the map to find their corresponding desk,
        and highlights it.
      </Paragraph>

      <ImageWrapper
        path={Proto}
        video={true}
        caption={"I got a prototype of the map highlighting"}
        caption={
          "Once I got a prototype of SVG highlighting to work (gorgeous ik), it was off to the races."
        }
      />
      <Paragraph>
        That was all well and good, but we didn't actually have an SVG map. So I
        made one. It was pretty tedious, but I developed a process that made it
        fairly straightforward once I completed an entire floor (there were
        three total). All the while, I went back to code periodically to check
        that the SVG approach would work, and fine-tuned as necessary (e.g.
        adding text to label office "landmarks").
      </Paragraph>

      <ImageWrapper
        path={Figma}
        video={false}
        caption={
          "I manually traced our floors in Figma, named each desk layer with an ID, then exported the map as an SVG (including the ID)."
        }
      />
      <Paragraph>
        Once the functionality was working, I hurried to put together a visually
        decent front-end. I added perspective to the map (we have 3 floors) via
        CSS transforms, which took some iterating since SVGs
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://css-tricks.com/lodge/svg/"
            target="_blank"
            rel="noopener noreferrer"
          >
            interact with CSS differently
          </a>{" "}
        </span>
        than standard HTML. Meanwhile, the other two engineers hooked up the
        search to our employee database and enabled the employee metadata
        (fortunately,
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://www.namely.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Namely
          </a>{" "}
        </span>
        has an
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://developers.namely.com/1.0/getting-started"
            target="_blank"
            rel="noopener noreferrer"
          >
            API
          </a>{" "}
        </span>
        that gave us exactly what we were looking for).
      </Paragraph>
      <Paragraph>
        Stitching it all together yielded a demo-able project.{" "}
        <b>Check it out:</b>
      </Paragraph>

      <ImageWrapper
        path={Demo}
        video={true}
        caption={
          "The final product: CSS transforms enabled perspective, and brought some life to the map."
        }
      />
      <ResultsWrapper>
        <Results>Results</Results>
      </ResultsWrapper>
      <Paragraph>
        We got some oohs and ahs from the crowd during our presentation, and
        ended up winning <b>first place</b> for "Employee Happiness" (best
        internal project). Of course, there's a ton more we would've wanted to
        do, but...hackathon :)
      </Paragraph>
    </ProjectWrapper>
  </div>
);

export default Hackathon;
