import React, { Component } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";

import arrowDark from "../../Assets/arrow-white.svg";
import arrowLight from "../../Assets/arrow-blue.svg";

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  margin-bottom: 40px;
  border-radius: 8px;
  box-shadow: ${props =>
    !props.dark
      ? "0 6px 24px rgba(0, 0, 0, 0.1)"
      : "0 6px 24px rgba(0, 0, 0, 0.6 )"};

  cursor: pointer;
  transition: all 200ms ease-out;

  :hover {
    box-shadow: ${props =>
      !props.dark
        ? "0 12px 48px rgba(0, 0, 0, 0.1)"
        : "0 12px 48px rgba(0, 0, 0, 0.7)"};
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 200px;
  background-image: url(${props => props.image});
  background-size: cover;
  background-position: center;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
  background-color: ${props => (!props.dark ? "white" : "#001747")};
  color: ${props => (!props.dark ? "#222" : "white")};
`;

const BottomWrapper = styled.div`
  flex-grow: 1;
  margin-right: 16px;
`;

const ProjectTitle = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 8px;
  transition: color 0.15s ease-out;
`;

const Description = styled.span`
  font-size: 14px;
  color: ${props => (!props.dark ? "#757575" : "#A1AFCE")};
`;

const Circle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: ${props => (!props.dark ? "#e0e7f4" : "#123681")};
  opacity: 0;
  transform: translateX(20px);
  transition: all 0.25s ease-out;

  ${ButtonWrapper}:hover & {
    opacity: 1;
    transform: translateX(0px);
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

class AdvanceButton extends Component {
  constructor() {
    super();
  }

  render() {
    return (
      <ButtonWrapper
        dark={this.props.dark}
        onClick={() => {
          this.props.history.push(`/${this.props.screen}`);
        }}
      >
        <ImageWrapper image={this.props.image}></ImageWrapper>
        <BottomSection dark={this.props.dark}>
          <BottomWrapper>
            <ProjectTitle>{this.props.buttonText}</ProjectTitle>
            <Description dark={this.props.dark}>
              {this.props.description}
            </Description>
          </BottomWrapper>
          <Circle dark={this.props.dark}>
            {!this.props.dark ? (
              <img src={arrowLight} />
            ) : (
              <img src={arrowDark} />
            )}
          </Circle>
        </BottomSection>
      </ButtonWrapper>
    );
  }
}

AdvanceButton.propTypes = {
  screen: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dark: PropTypes.bool.isRequired
};

export default withRouter(AdvanceButton);
