import React from "react";
import "./App.css";

import Task from "./Components/Pages/Task";

function App() {
  return (
    <div className="App">
      {/* used to be <Task /> */}
      <p>
        This is old lol go{" "}
        <a style={{ textDecoration: "underline" }} href="https://lawder.me/">
          here
        </a>
      </p>
    </div>
  );
}

export default App;
