import React from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route } from "react-router-dom";

// import pages
import Home from "./Home";
import Work from "./Work";
// import Work2 from "./Work2";
import About from "./About";
import Password from "./Password";
import OneTap from "./OTM";
import SSV from "./SSV";
import HospitalPrices from "./HospitalPrices";
import BlendMaps from "./Hackathon";

const StyledTask = styled.div`
  width: 100%;
  // padding: 28px 24px;
`;
// need a state which is isAuthenticated, which updates and triggers re-render

class Task extends React.Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: false
    };
  }

  componentWillMount() {
    let checkAuth = sessionStorage.getItem("lawderAuth");
    if (checkAuth) {
      this.setState({ isAuthenticated: true });
    }
  }

  validAuth = () => {
    this.setState({ isAuthenticated: true });
    sessionStorage.setItem("lawderAuth", true);
  };

  render() {
    return (
      <StyledTask>
        <Router>
          <div className="route-wrapper">
            <Route exact path={"/"} component={Home} />
            <Route
              exact
              path={"/Work"}
              render={props =>
                this.state.isAuthenticated ? (
                  <Work {...props} />
                ) : (
                  <Password {...props} handleCheck={this.validAuth} />
                )
              }
            />

            <Route
              exact
              path={"/About"}
              render={props =>
                this.state.isAuthenticated ? (
                  <About {...props} />
                ) : (
                  <Password {...props} handleCheck={this.validAuth} />
                )
              }
            />
            <Route exact path={"/Work/OneTap"} component={OneTap} />
            <Route exact path={"/Work/SSV"} component={SSV} />
            <Route
              exact
              path={"/Work/HospitalPrices"}
              component={HospitalPrices}
            />
            <Route exact path={"/Work/BlendMaps"} component={BlendMaps} />
          </div>
        </Router>
      </StyledTask>
    );
  }
}

export default Task;
