import React from "react";

import ReactMapGL, {
  LinearInterpolator,
  FlyToInterpolator
} from "react-map-gl";
// import * to import all; necessary because d3 doesn't have "default" exports
import * as d3 from "d3-ease";

class Map extends React.Component {
  constructor() {
    super();
    this.state = {
      viewport: {
        width: "100%",
        height: "100%",
        latitude: 37.7577,
        longitude: -122.4376,
        zoom: 1,
        pitch: 50,
        bearing: 0
      }
    };
  }

  componentDidMount() {
    let that = this;
    // I should really use onTransitionEnd (Function)

    setTimeout(function() {
      that.gotoSF();
    }, 1000);
    // wait 10 seconds
    setTimeout(function() {
      that.gotoGG();
    }, 7000);
    // wait 10 seconds
    setTimeout(function() {
      that.gotoDowntown();
    }, 17000);
  }

  componentWillUnmount() {
    clearInterval();
  }

  // transition zoom to SF
  gotoSF = () => {
    const viewport = {
      ...this.state.viewport,
      latitude: 37.7777,
      longitude: -122.4176,
      zoom: 12,
      transitionDuration: 5000,
      transitionInterpolator: new FlyToInterpolator(),
      transitionEasing: d3.easeCubic
    };
    this.setState({ viewport });
  };

  // rotate and go to GG
  gotoGG = () => {
    const viewport = {
      ...this.state.viewport,
      latitude: 37.8,
      longitude: -122.473,
      zoom: 14,
      transitionDuration: 10000,
      bearing: 160
    };
    this.setState({ viewport });
  };

  // go to Downtown and Zoom
  gotoDowntown = () => {
    const viewport = {
      ...this.state.viewport,
      latitude: 37.79,
      longitude: -122.397,

      // latitude: 37.798,
      // longitude: -122.399,

      zoom: 15,
      bearing: 0,
      transitionDuration: 40000
    };
    this.setState({ viewport });
  };

  // go to Downtown and Zoom
  Pan = () => {
    const viewport = {
      ...this.state.viewport,
      // latitude: 37.79,
      // longitude: -122.397,

      latitude: 37.79,
      longitude: -122.397,

      zoom: 16,
      bearing: 0,
      transitionDuration: 50000
    };
    this.setState({ viewport });
  };

  rotate2 = () => {
    console.log("rotate 2");
    const viewport = {
      ...this.state.viewport,
      bearing: this.state.viewport.bearing - 300,
      transitionDuration: 45000,
      transitionEasing: d3.easeLinear
    };
    this.setState({ viewport });

    // this.setState(state => ({bearing: state.bearing + 1}))
  };

  // I think this is just for user interaction
  _onViewportChange = viewport => {
    this.setState({ viewport });
  };

  render() {
    return (
      <ReactMapGL
        {...this.state.viewport}
        onViewportChange={this._onViewportChange}
        mapboxApiAccessToken={
          "pk.eyJ1IjoiaG9vcHN6biIsImEiOiJjaXhhZjR3aHAwMDd4MnRzMWExaGMwN3RuIn0.t8TbReRw_5MrbED0grGAhA"
        }
        // mapStyle="mapbox://styles/hoopszn/ck6kyp47721re1ioeeckh19z0"
        mapStyle="mapbox://styles/hoopszn/ck6l0fc6e0fy41il9x7uwntqp"
      />
    );
  }
}

export default Map;
