import React from "react";
import styled from "styled-components";
import ImageZoom from "react-medium-image-zoom";

const StyledWrapper = styled.div`
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 48px;
  width: 100%;
  max-width: 880px;
`;

const Content = styled.div`
  width: 100%;
  margin-bottom: 8px;
`;

const Video = styled.video`
  width: 100%;
`;

const Caption = styled.span`
  font-size: 14px;
  color: #757575;
`;

const ImageWrapper = props => (
  <StyledWrapper>
    <Content>
      {!props.video ? (
        <ImageZoom
          image={{
            src: props.path,
            style: { width: "100%", borderRadius: "4px" }
          }}
        />
      ) : (
        <Video autoPlay muted loop>
          <source src={props.path} type="video/mp4" />
          Your browser does not support the video tag :(
        </Video>
      )}
    </Content>
    <Caption>{props.caption}</Caption>
  </StyledWrapper>
);

export default ImageWrapper;
