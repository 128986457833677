import React from "react";
import styled from "styled-components";

import { motion } from "framer-motion";

import LoadingSpinner from "../UI/LoadingSpinner";
import Nav from "../UI/Nav";

import me from "../../Assets/About/me-profile.jpg";
import hidden1 from "../../Assets/About/ball-dreams.png";
import hidden2 from "../../Assets/About/ball-spin.png";
import hidden3 from "../../Assets/About/ball-hold.png";
// import arrow from "../../Assets/arrow-right.svg";
import resume from "../../Assets/About/2019-resume.pdf";

const AboutWrapper = styled(motion.div)`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
`;

const ContentWrapper = styled.div`
  display: flex;
  @media (max-width: 640px) {
    display: block;
  }
`;

const LeftPane = styled.div`
  width: 40vw;
  height: 100vh;
  // background: #ededed;
  padding: 80px;
  padding-top: 120px;
  overflow: hidden;
  @media (max-width: 640px) {
    width: 100%;
    height: auto;
    padding: 80px 40px;
  }
`;

const RightPane = styled.div`
  width: 60vw;
  min-height: 100vh;
  padding: 24px 80px;
  padding-top: 120px;
  @media (max-width: 640px) {
    width: 100%;
    height: auto;
    padding: 24px;
  }
`;

const Pattern = styled(motion.div)`
  position: absolute;
  width: 28vw;
  height: 36vw;
  background: linear-gradient(to bottom left, pink, #0035a7);
  border-radius: 8px;
  @media (max-width: 640px) {
    width: 60vw;
    height: 40vh;
  }
`;

const Image = styled(motion.img)`
  position: relative;
  left: 40px;
  top: 40px;
  width: 28vw;
  border-radius: 8px;
  z-index: 5;
  box-shadow: -8px -4px 40px rgba(0, 0, 0, 0.3);
  @media (max-width: 640px) {
    width: 60vw;
  }
`;

const HiddenImage1 = styled(motion.img)`
  position: absolute;
  left: 40px;
  top: 40px;
  width: 20vw;
  border-radius: 8px;
  z-index: 6;
  box-shadow: -8px -4px 40px rgba(0, 0, 0, 0.3);

  @media (max-width: 640px) {
    width: 60vw;
    left: 120px;
    top: 60vh;
  }
`;

const HiddenImage2 = styled(motion.img)`
  position: absolute;
  left: 19vw;
  top: 160px;
  width: 20vw;
  border-radius: 8px;
  z-index: 7;
  box-shadow: -8px -4px 40px rgba(0, 0, 0, 0.3);

  @media (max-width: 640px) {
    width: 60vw;
    left: 40px;
    top: 65vh;
  }
`;

const HiddenImage3 = styled(motion.img)`
  position: absolute;
  left: 120px;
  bottom: 40px;
  width: 20vw;
  border-radius: 8px;
  z-index: 8;
  box-shadow: -8px -4px 40px rgba(0, 0, 0, 0.3);

  @media (max-width: 640px) {
    width: 60vw;
    left: 100px;
    top: 80vh;
  }
`;

const ProjectHeader = styled.div`
  margin: 24px 0 24px 0;
`;

const BackButton = styled(motion.a)`
  display: inline-block;
  outline: none;
  text-decoration: none;

  margin-top: 24px;

  background: white;
  padding: 8px 0 8px 0;

  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 200ms ease-out;
  color: #757575;

  :hover {
    color: #222;
  }

  :hover img {
    transform: rotate(180deg) translateX(4px);
    color: #222;
    opacity: 1;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

const BackArrow = styled.img`
  transform: rotate(180deg);
  opacity: 0.5;
  width: 20px;
  margin-right: 8px;
  transition: all 200ms ease-out;
`;

const H1 = styled.h1`
  font-size: 32px;
  font-family: "Merriweather", serif;
  font-weight: 700;
`;

const Paragraph = styled.div`
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
  font-size: 18px;
  line-height: 1.7;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  margin-top: 40px;

  @media (max-width: 640px) {
    flex-direction: column;
    max-width: 100%;
  }
`;

const LinkButton = styled.a`
  outline: none;
  text-decoration: none;
  display: inline-block;
  padding: 12px 24px;
  margin-right: 16px;
  background: white;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: #757575;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  transition: all 200ms ease-out;

  :hover {
    transform: translateY(-2px);
  }

  @media (max-width: 640px) {
    margin-bottom: 16px;
    text-align: center;
  }
`;

let counter = 0;

class About extends React.Component {
  constructor() {
    super();
    this.state = {
      imageReady: false,
      triggerBasketball: false,
      basketballLoaded: false
    };
  }

  handleLoad = () => {
    this.setState({
      imageReady: true
    });
  };

  triggerBasketball = () => {
    this.setState({ triggerBasketball: true });
  };

  basketballReady = () => {
    counter += 1;
    if (counter === 3) {
      this.setState({ basketballLoaded: true });
    }
  };

  render() {
    return (
      <div>
        {!this.state.imageReady && <LoadingSpinner />}

        <AboutWrapper
          visible={this.state.imageReady}
          transition={
            this.state.imageReady ? { duration: 1, delay: 0.2 } : null
          }
          initial={{ opacity: 0 }}
          animate={this.state.imageReady ? { opacity: 1 } : { opacity: 0 }}
        >
          <Nav />
          <ContentWrapper>
            <LeftPane>
              <Pattern
                transition={
                  this.state.imageReady ? { duration: 0.6, delay: 0.3 } : null
                }
                initial={{ opacity: 0, y: 80 }}
                animate={
                  this.state.imageReady
                    ? { opacity: 1, y: 0 }
                    : { opacity: 0, y: 80 }
                }
              ></Pattern>
              <Image
                src={me}
                onLoad={this.handleLoad}
                transition={
                  this.state.imageReady ? { duration: 0.6, delay: 0.3 } : null
                }
                initial={{ opacity: 0, y: -80 }}
                animate={
                  this.state.imageReady
                    ? { opacity: 1, y: 0 }
                    : { opacity: 0, y: -80 }
                }
              />

              <HiddenImage1
                src={hidden1}
                onLoad={this.basketballReady}
                transition={
                  this.state.triggerBasketball
                    ? { duration: 0.4, delay: 0 }
                    : null
                }
                initial={{ opacity: 0, y: -80 }}
                animate={
                  this.state.triggerBasketball
                    ? { opacity: 1, y: 0 }
                    : { opacity: 0, y: -80 }
                }
              />
              <HiddenImage2
                src={hidden2}
                onLoad={this.basketballReady}
                transition={
                  this.state.triggerBasketball
                    ? { duration: 0.4, delay: 0.4 }
                    : null
                }
                initial={{ opacity: 0, x: 80 }}
                animate={
                  this.state.triggerBasketball
                    ? { opacity: 1, x: 0 }
                    : { opacity: 0, x: 80 }
                }
              />
              <HiddenImage3
                src={hidden3}
                onLoad={this.basketballReady}
                transition={
                  this.state.triggerBasketball
                    ? { duration: 0.4, delay: 0.8 }
                    : null
                }
                initial={{ opacity: 0, y: 80 }}
                animate={
                  this.state.triggerBasketball
                    ? { opacity: 1, y: 0 }
                    : { opacity: 0, y: 80 }
                }
              />
            </LeftPane>
            <RightPane>
              {/* <BackButton href="../">
                <ButtonContent>
                  <BackArrow src={arrow} />
                  <span>back home</span>
                </ButtonContent>
              </BackButton> */}

              <ProjectHeader>
                <H1>Lawder Paul</H1>
              </ProjectHeader>
              <Paragraph>
                I'm a designer who codes, obsessed with making the complex
                simple. I'm most enchanted by the least enchanting&mdash;thorny
                problem spaces like
                <span className="link-wrapper">
                  {" "}
                  <a className="link linkedin" href="/Work/OneTap">
                    mortgage lending
                  </a>{" "}
                </span>
                and
                <span className="link-wrapper">
                  {" "}
                  <a className="link linkedin" href="/Work/HospitalPrices">
                    healthcare costs
                  </a>
                </span>
                &mdash;as I get excited by opportunity, not current state.
              </Paragraph>
              <Paragraph>
                I'm naturally inquisitive (or maybe it's my short attention
                span), which has led me to be a bit of a polymath: in addition
                to design, I have some background in data analytics and biz dev.
                I've become increasingly interested in engineering over the past
                ~2 years, so working on that in my free time. Passionate about
                people, tooling, and anything else that compounds.
              </Paragraph>
              <Paragraph>
                So yeah I think about
                <span className="link-wrapper">
                  {" "}
                  <a className="link linkedin" href="/Work">
                    "work"
                  </a>{" "}
                </span>
                a lot, but I don't take things
                <span
                  className={
                    this.state.basketballLoaded ? "link-wrapper" : null
                  }
                >
                  {" "}
                  <a
                    className={this.state.basketballLoaded ? "link" : null}
                    onClick={this.triggerBasketball}
                  >
                    too seriously
                  </a>
                </span>
                .
              </Paragraph>
              <ButtonsWrapper>
                <LinkButton
                  className="email-button"
                  href="mailto:lawderpaul@gmail.com"
                  rel="noopener noreferrer"
                >
                  Email
                </LinkButton>
                <LinkButton
                  className="linkedin-button"
                  href="https://www.linkedin.com/in/nba/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  LinkedIn
                </LinkButton>
                <LinkButton
                  className="github-button"
                  href="https://github.com/lawderp"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GitHub
                </LinkButton>
                <LinkButton
                  className="resume-button"
                  href={resume}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Resume
                </LinkButton>
              </ButtonsWrapper>
            </RightPane>
          </ContentWrapper>
        </AboutWrapper>
      </div>
    );
  }
}

export default About;
