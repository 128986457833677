import React from "react";
import styled from "styled-components";

import chevron from "../../Assets/About/chevron-down.svg";

const DropdownWrapper = styled.div`
  position: fixed;
  top: 0;
  margin-top: 8px;
  margin-left: 8px;

  font-size: 14px;
  z-index: 20;
  background: none;
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 16px;
  padding-right: 12px;

  cursor: pointer;
  user-select: none;
  background: white;

  border-radius: 4px;
  transition: background 200ms ease-out;

  :hover {
    background: #f2f2f2;
  }

  :hover > img {
    transform: translateY(2px);
  }
`;

const Icon = styled.img`
  width: 20px;
  padding-top: 2px;
  transition: transform 150ms ease-out;
  margin-left: 4px;
  opacity: 0.6;
`;

const OptionsList = styled.ul`
  margin-top: 8px;
  list-style: none;
  padding: 8px;
  // padding: 16px 24px;
  // border: solid 1px grey;
  border-radius: 4px;
  user-select: none;
  background: white;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15);
`;

const ListItem = styled.li`
  font-size: 14px;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: background 150ms ease-out;

  :hover {
    background: #e6ebf6;
  }
`;

const Dot = styled.div`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #0035a7;
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  flex-shrink: 0;
  margin-right: 8px;
  opacity: 0.6;
`;

const LinkButton = styled.a`
  display: inline-block;
  outline: none;
  text-decoration: none;
  width: 100%;
  padding: 16px;
  padding-left: 8px;
  border-radius: 4px;
  transition: all 150ms ease-out;

  color: ${props => (props.highlighted ? "#0035a7" : "#757575")}
  :hover {
    // background: #e6ebf6;
    color: #0035a7;
  }
`;

class Nav extends React.Component {
  constructor() {
    super();
    this.state = {
      listOpen: false,
      aboutHighlight: false,
      workHighlight: false
    };
  }

  componentWillMount() {
    let url = window.location.href;
    // last 6 will always include the paths; hacky eek
    let last6 = url.slice(-6);
    if (last6.includes("About")) {
      this.setState({ aboutHighlight: true });
    } else if (last6.includes("Work")) {
      this.setState({ workHighlight: true });
    }
  }

  toggleList = () => {
    console.log("toggling list");
    this.setState({ listOpen: !this.state.listOpen });
  };

  render() {
    return (
      <DropdownWrapper>
        <DropdownHeader onClick={this.toggleList}>
          <span>lawder.design</span>
          <Icon src={chevron} />
        </DropdownHeader>

        {this.state.listOpen && (
          <OptionsList>
            <ListItem>
              <LinkButton href="/">Home</LinkButton>
            </ListItem>
            <ListItem>
              <LinkButton href="/About" highlighted={this.state.aboutHighlight}>
                About
              </LinkButton>
              <Dot visible={this.state.aboutHighlight} />
            </ListItem>
            <ListItem>
              <LinkButton href="/Work" highlighted={this.state.workHighlight}>
                Work
              </LinkButton>
              <Dot visible={this.state.workHighlight} />
            </ListItem>
          </OptionsList>
        )}
      </DropdownWrapper>
    );
  }
}

export default Nav;
