import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import ProjectHeader from "../UI/ProjectHeader";
import ImageWrapper from "../UI/ImageWrapper";
import LoadingSpinner from "../UI/LoadingSpinner";
import Nav from "../UI/Nav";

import Fortune from "../../Assets/OTM/Fortune-clip.mov";
import BlendPlatform from "../../Assets/OTM/blend-platform.png";
import Veracity from "../../Assets/OTM/otm-pa.png";
import APIs from "../../Assets/OTM/apis-export.png";
import Journey from "../../Assets/OTM/borrower-journey.png";
import Personas from "../../Assets/OTM/otm-personas.png";

import Explo from "../../Assets/OTM/explo.png";
import Docs from "../../Assets/OTM/otm-doc.png";
import Final from "../../Assets/OTM/final-mocks.png";
import MicroSite from "../../Assets/OTM/micro-site.mov";
import Slack from "../../Assets/OTM/otm-slack.png";
import scrollgif from "../../Assets/OTM/scroll-down.gif";

const OTMWrapper = styled(motion.div)`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  // padding-top: 32px;
`;

const WaitingWrapper = styled(motion.div)`
  margin: 0 auto;
  margin-top: 24px;
  text-align: center;
`;

const SecondWait = styled.p`
  margin-top: 8px;
`;

const HeroWrapper = styled.div`
  background: linear-gradient(90deg, #00f 50%, transparent 50.1%);
  // background: ${props => "rgba(0, 0, 0, " + props.scrollState / 100 + ")"};
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
`;

const HeroVideo = styled.video`
  mix-blend-mode: multiply;
`;

const ScrollWrapper = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 70%;
  left: 50vw;
  transform: translate(-50%, -50%);
  // background: rgba(0, 0, 0, 0.5);
  padding: 16px 8px;
  border-radius: 8px;
  color: white;
  opacity: ${props =>
    (1.2 - props.scrollState / 100) *
    (1 - props.scrollState / 100) *
    (1 - props.scrollState / 100) *
    (1 - props.scrollState / 100)};
`;

const ScrollText = styled.p`
  color: white;
  font-size: 14px;
  margin-bottom: 8px;
  opacity: 0.8;
`;

const Gif = styled.img`
  width: 120px;
  opacity: 0.8;
`;

const ProjectWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 120px;

  transition: opacity 1s ease-out;
  @media (max-width: 640px) {
    padding: 0 24px;
  }
`;

const Paragraph = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
  font-size: 18px;
  line-height: 1.7;
`;

const ResultsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 16px;
  max-width: 640px;
  text-align: left;
`;

const Results = styled.h3`
  font-size: 20px;
  font-weight: 500px;
`;

class OTM extends React.Component {
  constructor() {
    super();
    this.videoRef = React.createRef();
    this.contentRef = React.createRef();

    this.state = {
      videoLoaded: false,
      timeElapsed: 0,
      scrollState: null,
      windowHeight: null,
      scrollInstructions: false
    };
  }

  componentDidMount() {
    this.setState({ windowHeight: window.innerHeight });
    this.checkVideo();
    window.addEventListener("scroll", this.handleScroll, { passive: true });
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll = () => {
    let scrollY = document.documentElement.scrollTop;
    let percent = (scrollY / this.state.windowHeight) * 100;
    let convertedPercent;
    if (percent >= 100) {
      convertedPercent = 100;
    } else {
      convertedPercent = percent;
    }
    this.setState({ scrollState: convertedPercent });
  };

  checkVideo = () => {
    let DOMnode = this.videoRef.current;
    let that = this;
    const interval = setInterval(function() {
      let readyState = DOMnode.readyState;
      that.setState({ timeElapsed: that.state.timeElapsed + 500 });
      if (readyState > 3) {
        that.setState({
          videoLoaded: true // change
        });
        clearInterval(interval);
      }
    }, 500);
  };

  render() {
    return (
      <div>
        {!this.state.videoLoaded && <LoadingSpinner />}
        {!this.state.videoLoaded && this.state.timeElapsed > 8000 && (
          <WaitingWrapper
            transition={{ duration: 0.3, delay: 0 }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <p>Sorry it doesn't usually take this long...</p>
            {this.state.timeElapsed > 30000 && (
              <SecondWait>
                Ok maybe check your internet connection then email me
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="mailto:lawderpaul@gmail.com?subject=Lawder your site is slow as hell"
                    target="_blank"
                  >
                    lawderpaul@gmail.com
                  </a>
                </span>
              </SecondWait>
            )}
          </WaitingWrapper>
        )}
        <div>
          <Nav />
          <OTMWrapper
            visible={this.state.videoLoaded}
            transition={
              this.state.videoLoaded ? { duration: 1, delay: 0.2 } : null
            }
            initial={{ opacity: 0 }}
            animate={this.state.videoLoaded ? { opacity: 1 } : { opacity: 0 }}
          >
            <HeroWrapper scrollState={this.state.scrollState}>
              <HeroVideo
                width="100%"
                // height="100%"
                autoPlay
                muted
                loop
                ref={this.videoRef}
              >
                <source src={Fortune} type="video/mp4" />
                Your browser does not support the video tag :(
              </HeroVideo>
              <ScrollWrapper
                scrollState={this.state.scrollState}
                transition={
                  this.state.videoLoaded ? { duration: 1, delay: 5 } : null
                }
                initial={{ visibility: "hidden" }}
                animate={
                  this.state.videoLoaded
                    ? { visibility: "visible" }
                    : { visibility: "hidden" }
                }
              >
                <ScrollText>scroll down</ScrollText>
                <Gif src={scrollgif} />
              </ScrollWrapper>
            </HeroWrapper>

            <ProjectWrapper
              ref={this.contentRef}
              scrollState={this.state.scrollState}
            >
              <ProjectHeader title={"One-Tap Mortgage"} year={"2019"} />
              <Paragraph>
                Getting a mortgage is immensely stressful: it takes
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://static.elliemae.com/pdf/origination-insight-reports/EM_OIR_JULY2019.pdf"
                    target="_blank"
                  >
                    42 days
                  </a>{" "}
                </span>
                on average to close on a mortgage, loan files are often more
                than
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.huffpost.com/entry/expect-500page-mortgage-a_b_4604743"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    500 pages
                  </a>{" "}
                </span>
                long, and 1 in 3 Americans are
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.housingwire.com/articles/46384-americans-say-buying-a-home-is-most-stressful-event-in-modern-life"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    reduced to tears
                  </a>{" "}
                </span>
                at some point during the process.
              </Paragraph>
              <Paragraph>
                Since our founding in 2012,
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://blend.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blend
                  </a>{" "}
                </span>
                has been chipping away at making the mortgage process better
                through software.
              </Paragraph>
              <Paragraph>
                I led the design for what our CEO called the "One-Tap Mortgage":
                the idea that consumers should be able to get a verified{" "}
                <b>mortgage pre-approval with the push of a button</b>. If it
                sounds ambitious and even a little crazy, well it is. Our
                current mortgage application takes about 30 minutes (median)
                just to fill out (underwriting is done later). The media has
                taken note, as One-Tap is already making
                <span className="link-wrapper">
                  {" "}
                  <a className="link" href="#headlines">
                    headlines
                  </a>{" "}
                </span>
                despite not yet being released (slated for end of 2019).
              </Paragraph>
              <Paragraph>
                The secret to reducing a
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://blog.credit.com/2017/11/how-long-does-it-take-to-get-approved-for-a-mortgage-113416/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    days to months-long
                  </a>{" "}
                </span>
                process—obtaining a mortgage pre-approval—to mere seconds? A
                timely confluence of APIs that had been built, and that we had
                integrated with, over the last several years.
              </Paragraph>

              <ImageWrapper
                path={BlendPlatform}
                video={false}
                caption={
                  "The Blend platform: pulling data straight from the (verified) source."
                }
              />

              <Paragraph>
                These connected services put Blend at an inflection point along
                the "stength vs. ease" curve. Historically, the ease (measured
                in time) of getting pre-approved has been inversely correlated
                with the strength (veracity) of the pre-approval. That is,
                taking more time to review and verify an applicant's information
                generally meant more confidence and a stronger pre-approval
                letter. Many traditional lenders have biased toward strength
                (especially after 2008), while a lot of new/online lenders skew
                toward ease of the pre-approval process (e.g. Rocket Mortgage).
              </Paragraph>
              <Paragraph>
                Because of Blend's differentiated approach—we serve large
                lenders and collaborate closely with Fannie Mae, but are a
                software company at heart—we were able to both. That is, we were
                in a position to generate the{" "}
                <b>fastest verified pre-approval ever</b>.
              </Paragraph>

              <ImageWrapper
                path={Veracity}
                video={false}
                caption={
                  "Visualizing this relationship helped to orient the team around the opportunity at hand."
                }
              />

              <Paragraph>
                While this meant (drastically) fewer screens for the consumer,
                the UX and eng side of things was far from simple. We were
                pulling in data from a multitude <i>x</i> of sources, each
                capable of producing their own <i>n</i> outcomes, thus leading
                to some...large number of possible scenarios we had to consider,
                and I had to design for.
              </Paragraph>
              <Paragraph>
                I had worked on a couple of the aforementioned API projects
                prior—making me a sort of de facto domain expert—so I helped
                drive the technical design of the calls and workflows.{" "}
              </Paragraph>
              <Paragraph>
                I created and iterated on a series of flow diagrams, with a
                fidelity ranging from typical user flow to
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://en.wikipedia.org/wiki/Technical_drawing"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    technical drawing
                  </a>
                </span>
                . This was done in close partnership with engineering, as there
                were intricacies of our back-end structure—TLDR our workflow
                engine is quite rigid woo!—that eng had to educate me on.
              </Paragraph>

              <ImageWrapper
                path={APIs}
                video={false}
                caption={"LucidChart and Flowkit became my friends :)"}
              />

              <Paragraph>
                While fairly obvious that making a process asymptotically
                infinitely faster is a *good* thing, we were less sure about how
                exactly we should orient the experience (e.g. more streamlined
                or more transparent?).
              </Paragraph>
              <Paragraph>
                Blend has provided a "standard" digital mortgage application for
                several years, which has equipped us with numerous insights into
                borrower behavior. But One-Tap is a fairly greenfield
                experience, so we felt it was a good time to re-calibrate our
                understanding of borrower goals, motivations, and frustrations.
              </Paragraph>
              <Paragraph>
                I partnered with Ray from our User Research team to drive
                discovery work: he led 15 remote interviews with people from
                around the country currently going through the home-buying
                process. We then turned those conversations into insights,
                identified personas, and did some journey mapping with employees
                across Blend (side note: great way to reinvigorate
                cross-functional evangelism around the company mission).
              </Paragraph>

              <ImageWrapper
                path={Journey}
                video={false}
                caption={
                  "Patterns emerged after laying out the pre-approval journey for 15 (real) borrowers."
                }
              />
              <ImageWrapper
                path={Personas}
                video={false}
                caption={
                  "Personas helped to ground the experience and provide a benchmark for evaluating designs."
                }
              />

              <Paragraph>
                Ideally we would've done this discovery work up front, but
                company priorities and customer commits catapulted One-Tap to
                the top of our roadmap, which meant building the plane as we
                were flying it (#startup). Regardless, this research was used to
                inform some v1 design decisions, and certainly will shape the v2
                and beyond.
              </Paragraph>
              <Paragraph>
                While engineering got started laying the groundwork, I partnered
                with product to align on scope and explore some initial
                concepts.
              </Paragraph>
              <Paragraph>
                Striking a balance between simple and transparent proved
                particularly challenging. With just One Tap<sup>TM</sup>, there
                was a ton of data that we were pulling or assuming behind the
                scenes—a mortgage is oft summarized as a
                <span className="link-wrapper">
                  {" "}
                  "
                  <a
                    className="link"
                    href="https://themortgageadvantage.com/mortgage-colonoscopy/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    financial colonoscopy
                  </a>
                </span>
                "—so how much of this logic should we
                (as-intelligently-as-we-can) just handle ourselves vs. expose to
                the consumer?
              </Paragraph>

              <ImageWrapper
                path={Explo}
                video={false}
                caption={
                  'Playing with the concept of "Buying Power", and all that goes into it.'
                }
              />

              <Paragraph>
                We were able to squeeze in one round of usability testing (tight
                timelines woo) that helped eliminate certain concepts that
                proved jarring ("what just happened?"). Other ideas were a bit
                too bold and thus shut down by our customers' (Big Banks)
                compliance teams (we're a white label solution).
              </Paragraph>
              <Paragraph>
                "Final" designs—this is just <i>v1</i> of <i>vMany</i>—were a
                careful harmony of empowering the user to build an accurate
                financial profile / not being overly presumptuous, while still
                doing much of the heavy lifting on behalf of the user.
              </Paragraph>
              <Paragraph>
                The inputs that go into their pre-approval are displayed before
                the CTA to create the pre-approval, in an effort to encourage
                the user to review that their data is accurate and make any
                changes. These inputs are grouped into like cards, with header
                text that summarizes the data in an action-oriented way. The
                pre-approval workflow allows users to adjust their purchase
                price downward (never upward, of course) to more precisely make
                an offer, e.g. to avoid showing their hand to the seller.
              </Paragraph>

              <ImageWrapper
                path={Final}
                video={false}
                caption={
                  "Consumers are pre-approved by the third screen, then can fine tune their pre-approval as they wish."
                }
              />

              <Paragraph>
                And importantly, we made sure the designs deliver on our lofty
                goal: giving consumers a verified pre-approval letter in just
                seconds, which is quite literally thousands of times faster than
                the industry standard.
              </Paragraph>
              <Paragraph>
                As luck (or outright intention) would have it, I sit right next
                to the engineers building One-Tap, so there was a lot of
                collaboration IRL. But of course it's important that the logic
                get documented somewhere, so I made a flow diagram with
                accompanying documentation for each screen (what actions
                triggered a recalculation of Buying Power, which disqualified
                the user from getting a One-Tap mortgage, etc.).
              </Paragraph>

              <ImageWrapper
                path={Docs}
                video={false}
                caption={
                  "I included logic in the Figma file—even the simplest screens bore logic—and made eng Figma fans in the process :)"
                }
              />

              <ResultsWrapper>
                <Results>Marketing</Results>
              </ResultsWrapper>
              <Paragraph>
                Perhaps the most exciting project in Blend's history, there was
                a substantial marketing push in August and September as
                development work on v1 was wrapping up. Blend launched an entire
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://one-tap-intro.blend.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    mircrosite
                  </a>{" "}
                </span>
                dedicated to it (credit goes to an agency), with an accompanying
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://blend.com/news/future-lending-one-tap/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    blog post
                  </a>{" "}
                </span>
                from our CEO.
              </Paragraph>

              <ImageWrapper
                path={MicroSite}
                video={true}
                caption={
                  "The One-Tap launch made headlines and included a splashy microsite, because of course."
                }
              />

              <ResultsWrapper>
                <a name="headlines"></a>
                <Results>Media coverage</Results>
              </ResultsWrapper>

              <Paragraph>
                One-Tap has received media coverage from
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.cnbc.com/2019/09/23/startup-blend-gives-banks-one-tap-to-fight-quickens-rocket-mortgage.html"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    CNBC
                  </a>
                  ,{" "}
                </span>
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://qz.com/1546737/blend-says-mortgages-will-be-available-with-just-one-tap-on-a-phone/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Quartz
                  </a>
                  ,{" "}
                </span>
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.businessinsider.com/blend-is-simplifying-home-loan-applications-into-a-single-smartphone-tap-2019-4"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Business Insider
                  </a>
                  ,{" "}
                </span>
                and others. Our CEO and President went on Fortune to talk about
                it in August 2019, which you can view
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://fortune.com/2019/08/26/ex-fannie-mae-ceo-housing-will-be-fine-in-the-next-recession/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    here
                  </a>{" "}
                </span>
                (One-Tap discussion starts at 4:50).
              </Paragraph>

              <ResultsWrapper>
                <Results>Results</Results>
              </ResultsWrapper>
              <Paragraph>
                One-Tap has yet to be released—it's slated to go live in
                November 2019—as it's not quite through the gauntlet that is Big
                Bank Compliance Review Post-2008.
              </Paragraph>
              <Paragraph>
                As with every release, we have hypotheses about which metrics
                this project will drive. But One Tap isn't just a feature, or
                even product. It's neither a mortgage application nor mortgage
                calculator (conceptually,
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://stratechery.com/2019/neither-and-new-lessons-from-uber-and-vision-fund/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Neither, and New
                  </a>
                </span>
                ), and is closer to a "push" experience in an industry that has
                only ever had pull (people have always applied for mortgages,
                rather than e.g. lenders proactively telling people what they
                qualify for).
              </Paragraph>
              <Paragraph>
                It was revealed on stage at
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.nationalmortgagenews.com/conference/digitalmortgage-2019"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Digital Mortgage Conference
                  </a>{" "}
                </span>
                in September, and reception was overwhelmingly positive. From
                someone on our sales team:
              </Paragraph>
              <ImageWrapper
                path={Slack}
                video={false}
                caption={
                  'In additional to driving "normal" metrics, One-Tap could directly increase sales.'
                }
              />
              <Paragraph>
                That is, the One-Tap Mortgage is potentially so compelling, that
                it could actually <b>directly drive sales</b>. To lenders, it's
                the kind of offering that can transform their customer
                experience, and is now expected to push some of our prospects
                over the line. If so, that's at least one win. :)
              </Paragraph>
            </ProjectWrapper>
          </OTMWrapper>
        </div>
      </div>
    );
  }
}

export default OTM;
