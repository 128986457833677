import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Map from "../UI/Map";

// import "./App.css";

import Arrow from "../../Assets/arrow-right.svg";

const AppWrapper = styled.div`
  display: flex;
  overflow: hidden;
  color: white;
  background: #0f1a52;
  @media (max-width: 600px) {
    display: block;
  }
`;

const MapWrapper = styled(motion.div)`
  position: fixed;
  width: 45vw;
  height: 100vh;
  background: #0f1a52;
  @media (max-width: 600px) {
    width: 100vw;
    height: 49vh;
    // position: static;
  }
`;

const Overlay = styled.div`
  pointer-events: none;
  position: absolute;
  height: 100%;
  width: 45vw;
  z-index: 5;
  background: linear-gradient(
    90deg,
    rgba(15, 26, 82, 0) 0%,
    rgba(15, 26, 82, 0.7) 60%,
    rgba(15, 26, 82, 1) 95%
  );

  @media (max-width: 600px) {
    width: 100vw;
    height: 50vh;
    pointer-events: auto;
    z-index: 3;

    background: linear-gradient(
      180deg,
      rgba(15, 26, 82, 0) 0%,
      rgba(15, 26, 82, 0.6) 70%,
      rgba(15, 26, 82, 1) 100%
    );
  }
`;

const RightPane = styled.div`
  margin-left: 45vw;
  padding: 48px;
  height: 100vh;
  max-height: 100vh;
  overflow-y: scroll;

  @media (max-width: 600px) {
    position: relative;
    margin-top: 50vh;
    margin-left: 0;
    padding: 24px;
    padding-bottom: 64px;
    background-color: #0f1a52;
    z-index: 600;
  }
`;

const TopSection = styled(motion.div)`
  margin-bottom: 72px;
`;

const Name = styled.h1`
  font-size: 24px;
  font-weight: 800;
  // font-weight: 700;
`;

const Hello = styled.span`
  opacity: 0.3;
  font-size: 18px;
  margin-bottom: ;
`;

const LocationWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  align-items: center;
`;

const Location = styled.p`
  margin-left: 12px;
  font-size: 15px;
`;

const Spacer = styled.div`
  max-height: 12vh;
  height: 200px;
  @media (max-width: 600px) {
    display: none;
  }
`;

const ContentWrapper = styled.div`
  margin-top: 16px;
  max-width: 700px;
`;

const ContentOne = styled(motion.h1)`
  // color: navy;
  font-size: 40px;
  font-weight: 500;
  font-family: TiemposHeadline;
  letter-spacing: 0.3px;
  margin-bottom: 32px;
  max-width: 620px;
  @media (max-width: 600px) {
    font-size: 32px;
  }
`;

const ContentTwo = styled(motion.div)`
  color: white;
  font-size: 18px;
  line-height: 1.7;
  font-weight: 400;
  margin-bottom: 24px;
`;

const ContentSection = styled.div`
  margin-bottom: 16px;
`;

const AboutLink = styled.a`
  outline: none;
  text-decoration: none;
  color: #757575;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  transition: color 200ms ease-out;

  :hover {
    color: #0035a7;
  }
`;

const WorkButton = styled(motion.a)`
  display: inline-block;
  // display: block;
  margin-top: 72px;
  outline: none;
  background: white;
  padding: 24px 32px 24px 28px;
  border: solid 2px black;
  border-radius: 8px;
  font-size: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 200ms ease-out;
  color: #222;
  text-decoration: none;
  :hover {
    // border-color: #0035a7;
    // color: #0035a7;
  }
`;

const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      render: false
    };
  }

  componentDidMount() {
    setTimeout(this.renderContent, 500);
  }

  renderContent = () => {
    this.setState({ render: true });
  };

  render() {
    return (
      <AppWrapper className="App">
        <MapWrapper
          // pose={this.state.render ? "visible" : "hidden"}

          transition={{ duration: 3, delay: 1 }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <Overlay className="overlay" />
          <Map />
        </MapWrapper>
        <RightPane>
          <Spacer />
          <ContentWrapper>
            <ContentOne
              transition={{ duration: 0.3, delay: 1.5 }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
            >
              Lawder is a designer who codes in San Francisco
            </ContentOne>
            <ContentTwo
              transition={{ duration: 0.3, delay: 1.7 }}
              initial={{ opacity: 0, y: 30 }}
              animate={{ opacity: 1, y: 0 }}
            >
              <ContentSection>
                I’m currently a Product Designer at
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.scale.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Scale AI
                  </a>
                </span>
                , creating tools to accelerate the development of machine
                learning. Previously I was at
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.blend.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Blend
                  </a>
                </span>
                , where I designed the
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://one-tap-intro.blend.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    One-Tap Mortgage
                  </a>
                </span>
                . And before that, I wore several hats at a data analytics
                startup acquired by Mastercard.
              </ContentSection>
              <ContentSection>
                I like using code to solve problems: I recently made
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="https://www.hospitalprices.wtf"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    hospitalprices.wtf
                  </a>
                </span>
                , and am now building a no-frills
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    // href="https://www.bluebird.money"
                    href="https://bluebird.money/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    personal finance web app
                  </a>
                </span>
                . I also read about venture&mdash;hmm not sure that's cool to
                admit&mdash;and watch unhealthy amounts of basketball (cooler).
              </ContentSection>
              <ContentSection>
                I’ve done some other stuff as well but don’t want to bore you.
                <span className="link-wrapper">
                  {" "}
                  <a
                    className="link"
                    href="mailto:lawderpaul@gmail.com"
                    rel="noopener noreferrer"
                  >
                    HMU
                  </a>
                </span>
                !
              </ContentSection>
            </ContentTwo>
            {/* <AboutLink href="/About">More about me -></AboutLink> */}
          </ContentWrapper>
          {/* <WorkButton
            href="/Work"
            transition={{ duration: 0.3, delay: 1.9 }}
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
          >
            <ButtonContent className="button-content">
              <span>View my work</span>
              <img src={Arrow} />
            </ButtonContent>
          </WorkButton> */}
        </RightPane>
      </AppWrapper>
    );
  }
}

export default App;
