import React from "react";
import styled from "styled-components";

import ProjectHeader from "../UI/ProjectHeader";
import ImageWrapper from "../UI/ImageWrapper";
import Nav from "../UI/Nav";

import SSVDocs from "../../Assets/SSV/ssv-docs.png";
import Flow from "../../Assets/SSV/ssv-flow.png";
import Mocks from "../../Assets/SSV/ssv-mocks.png";
import Proto from "../../Assets/SSV/ssv-proto.mov";

const ProjectWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  margin-bottom: 120px;
  padding-top: 48px;
  @media (max-width: 640px) {
    padding: 0 24px;
  }
`;

const Paragraph = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
  font-size: 18px;
  line-height: 1.7;
`;

const ResultsWrapper = styled.div`
  margin: 0 auto;
  margin-top: 40px;
  margin-bottom: 16px;
  max-width: 640px;
  text-align: left;
`;

const Results = styled.h3`
  font-size: 20px;
  font-weight: 500px;
`;

const SSV = () => (
  <div>
    <Nav />
    <ProjectWrapper>
      <ProjectHeader title={"Single Source Validation (SSV)"} year={"2018"} />
      <Paragraph>
        When you apply for a mortgage, you need to state where you work and how
        much money you make. But a lender doesn't then just take your word for
        it; rather, they need to verify that what you stated is actually true,
        often by looking at documentation (e.g. your W-2s).
      </Paragraph>
      <Paragraph>
        This is doubly painful, because it's high-friction for both 1)
        consumers, since they have to track down these documents and 2) lenders,
        since they have to manually review said docs, which usually takes days
        and sometimes weeks.
      </Paragraph>
      <Paragraph>
        Fortunately,
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://blend.com/blog/news/d1c-fannie-mae/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Fannie Mae
          </a>{" "}
        </span>
        (the government-sponsored enterprise that buys mortgages on the
        secondary market) had just completed work on an
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://www.fanniemae.com/portal/media/corporate-news/2017/single-source-api-servicing-marketplace-6620.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            API
          </a>{" "}
        </span>
        that automatically detects income from a users' bank account by looking
        for recurring direct deposits. And since this income comes directly from
        a verified source (bank account via third-party API such as
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://plaid.com/products/transactions/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Plaid
          </a>
        </span>
        ), the income itself is verified: no documentation needed.
      </Paragraph>
      <Paragraph>
        All this meant we could <b>do away with documentation entirely</b>
        —rather than e.g. speeding up document collection and approval—making
        this one of those "no one asked for a car, just faster horses" type
        projects.
      </Paragraph>
      <Paragraph>
        We were using an early version of Fannie's API, so there wasn't yet any
        formal documentation around it. To figure out how it worked, the PM and
        I sifted through the resources we were given: mainly a collection of
        sample responses (JSON) and Word documents.
      </Paragraph>

      <ImageWrapper
        path={SSVDocs}
        video={false}
        caption={
          "The API documentation was an elegant concoction of...JSON files and Word docs."
        }
      />
      <Paragraph>
        I then translated these resources into a flow diagram. The workflow we
        were building would effectively have to ask borrowers to match incomes
        they self-reported with incomes that the Fannie API detected. It also
        had to allow borrowers to handle new incomes that they hadn't previously
        self-reported, but that Fannie had surfaced (effectively, "do you want
        to add or ignore?").
      </Paragraph>

      <ImageWrapper
        path={Flow}
        video={false}
        caption={
          "Working backwards: translating the (Fannie) API logic to (Blend) workflows."
        }
      />
      <Paragraph>
        I explored different interactions for the aforementioned functionality,
        and got to work on a Framer Classic (RIP) prototype that we'd user test
        with people currently buying homes. Of course, we wouldn't be able to
        test with someone's actual direct deposits, and I was concerned that
        using totally fake deposits would be a distraction.
      </Paragraph>
      <Paragraph>
        To mitigate this issue, I prepended parts of the application prior to
        the SSV workflow, which allowed me to then inject some of their info
        (they they just entered) into the deposit descriptions. The result being
        that the direct deposits in the prototype actually looked something like
        the real ones they received.
      </Paragraph>

      <ImageWrapper
        path={Proto}
        video={true}
        caption={
          "Making user testing real-ish: deposit descriptions included subjects' actual name and employer."
        }
      />
      <Paragraph>
        Over 3 rounds of testing, we learned quite a bit. Most notably, initial
        designs caused users to start the workflow in a cognitive deficit: they
        were jarred by the <i>existence</i> of the workflow (technical
        constraints forced us to place it after they completed their
        application), which acted as a multiplier on any ensuing ambiguity. To
        address this, I began the workflow with a fork that effectively forced
        users to read → gain context, which made the tasks that followed far
        more straightforward.
      </Paragraph>

      <Paragraph>
        Final mocks started with this fork, then surfaced the direct deposits
        using a monospaced font to help establish their source (*detected* from
        the user's bank account). Details are hidden by default, keeping the
        task streamlined, but allowing the borrower to gain more context if it
        would aid them in matching. We added a subsequent screen in the flow
        that allowed users to add or ignore incomes they previously indicated
        didn't match—another screen, sure, but one that seemed to substantially
        reduce cognitive load. (one of our design principles at Blend is
        "clarity over clicks").
      </Paragraph>

      <ImageWrapper
        path={Mocks}
        video={false}
        caption={
          "After a few screens, consumers are relieved from providing income documention entirely."
        }
      />

      <ResultsWrapper>
        <Results>Results</Results>
      </ResultsWrapper>
      <Paragraph>
        It turns out that doing away with manual documentation makes the
        pre-approval process much faster. In the months that followed, borrowers
        that went through SSV managed to get a{" "}
        <b>Conditional Approval 2x quicker</b> than normal, from 15 days to 7.5.
      </Paragraph>
      <Paragraph>
        The SSV workflow also boasted a 98% pull-through (although thinking
        about that 2% is painful, since it's toward the very end of our
        application funnel; would love to revisit one day). Perhaps most
        importantly, this was one of the projects that unlocked the
        <span className="link-wrapper">
          {" "}
          <a className="link" href="/Work/OneTap" rel="noopener noreferrer">
            One Tap Mortgage.
          </a>
        </span>
      </Paragraph>
    </ProjectWrapper>
  </div>
);

export default SSV;
