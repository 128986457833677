import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";

import Nav from "../UI/Nav";
import ProjectButton from "../UI/ProjectButton";
import OTMBlog from "../../Assets/OTM/otm-blog.png";
import SSVMocks from "../../Assets/SSV/ssv-mocks.png";
import HospitalMeta from "../../Assets/HospitalPrices/meta-tag.png";
import BlendMap from "../../Assets/Hackathon/blend-map.png";

const WorkWrapper = styled.div`
  display: flex;
  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

const LeftPane = styled(motion.div)`
  width: 50vw;
  min-height: 100vh;
  padding: 40px;
  padding-top: 56px;
  @media (max-width: 600px) {
    width: 100vw;
    min-height: 0;
    padding: 40px 24px;
  }
`;

const RightPane = styled(motion.div)`
  width: 50vw;
  min-height: 100vh;
  background: #00133d;
  padding: 40px;
  padding-top: 56px;
  @media (max-width: 600px) {
    width: 100vw;
    min-height: 0;
    padding: 40px 24px;
  }
`;

const DesignHeader = styled.h2`
  margin-top: 16px;
  margin-bottom: 40px;
  font-size: 24px;
  text-align: center;
  font-weight: 500;
`;

const CodeHeader = styled.h2`
  font-size: 24px;
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
  color: white;
  font-family: "Roboto Mono", monospace;
  font-weight: 500;
`;

class Work extends React.Component {
  constructor() {
    super();
  }

  render() {
    return (
      <div>
        <Nav />

        <WorkWrapper>
          <LeftPane
            transition={{ duration: 0.3, delay: 0.3 }}
            initial={{ opacity: 0, x: -80 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <DesignHeader>Design</DesignHeader>
            <ProjectButton
              screen={"Work/OneTap"}
              buttonText={"One-Tap Mortgage"}
              image={OTMBlog}
              description={"The simplest mortgage approval ever."}
              dark={false}
            />
            <ProjectButton
              screen={"Work/SSV"}
              buttonText={"Single Source Validation (SSV)"}
              image={SSVMocks}
              description={"Self-serve income verification tool for borrowers."}
              dark={false}
            />
          </LeftPane>

          <RightPane
            transition={{ duration: 0.3, delay: 0.3 }}
            initial={{ opacity: 0, x: 80 }}
            animate={{ opacity: 1, x: 0 }}
          >
            <CodeHeader>Design + Code</CodeHeader>
            <ProjectButton
              screen={"Work/HospitalPrices"}
              buttonText={"hospitalprices.wtf"}
              image={HospitalMeta}
              description={"An attempt at price transparency in healthcare."}
              dark={true}
            />
            <ProjectButton
              screen={"Work/BlendMaps"}
              buttonText={"Blend Maps"}
              image={BlendMap}
              description={"Interactive office map; hackathon winner 2019."}
              dark={true}
            />
          </RightPane>
        </WorkWrapper>
      </div>
    );
  }
}

export default Work;
