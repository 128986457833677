import React from "react";
import styled from "styled-components";

import ProjectHeader from "../UI/ProjectHeader";
import Nav from "../UI/Nav";

const ProjectWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding-top: 48px;
  margin-bottom: 120px;
  @media (max-width: 640px) {
    padding: 0 24px;
  }
`;

const Paragraph = styled.div`
  margin: 0 auto;
  margin-bottom: 16px;
  text-align: left;
  max-width: 640px;
  font-size: 18px;
  line-height: 1.7;
`;

const HospitalPrices = () => (
  <div>
    <Nav />
    <ProjectWrapper>
      <ProjectHeader title={"hospitalprices.wtf"} year={"2019"} />

      <Paragraph>
        I find the lack of price transparency in healthcare rather frustrating.
        So I made a website.
      </Paragraph>
      <Paragraph>
        <span className="link-wrapper">
          {" "}
          <a
            className="link"
            href="https://www.hospitalprices.wtf/"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.hospitalprices.wtf
          </a>{" "}
        </span>
      </Paragraph>
    </ProjectWrapper>
  </div>
);
export default HospitalPrices;
