import React from "react";
import styled, { keyframes } from "styled-components";

const LoadingWrapper = styled.div`
  margin: 0 auto;
  margin-top: 20vh;
  text-align: center;
`;

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(270deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
`;

const Spinner = styled.svg`
  animation: ${rotate} 1.4s linear infinite;
`;

const Path = styled.circle`
  stroke: #0035a7;
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: ${dash} 1.4s ease-in-out infinite;
`;

const LoadingSpinner = () => (
  <LoadingWrapper>
    <Spinner
      width="65px"
      height="65px"
      viewBox="0 0 66 66"
      xmlns="http://www.w3.org/2000/svg"
    >
      <Path
        fill="none"
        strokeWidth="4"
        strokeLinecap="round"
        cx="33"
        cy="33"
        r="30"
      ></Path>
    </Spinner>
  </LoadingWrapper>
);

export default LoadingSpinner;
